import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { QuoteContext } from "../../../Context/QuoteContext"
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo"
import styled from "@emotion/styled"
import {
  verifyMobilePin,
  requestMobilePin,
  modalResetError,
  toggleDispatchedPin,
  formResetError,
  updateField,
} from "../../../Context/Actions"
import { useTheme } from "emotion-theming"
import Icon from "../../../components/Icon/Icon"
import OneTimePin from "../../../components/GetQuote/Fields/OneTimePin/OneTimePin"
import OutlinedButton from "../../../components/GetQuote/Fields/Buttons/OutlinedButton"
import LinkButton from "../../../components/GetQuote/Fields/Buttons/LinkButton"
import Overlay from "../../../components/GlobalAssets/Overlay/overlay"
import {
  FormMessage,
  validateOneTimePin,
} from "../../../components/GetQuote/Fields/fields"
import Modal from "../../../components/GlobalAssets/Modal/modal"
import { QuoteContainer } from "./lagg-till-mobilnummer"
import ButtonLoader from "../../../components/GlobalAssets/ButtonLoader"
import insplanet from "../../../images/insplanet-logo.jpg"
import compricer from "../../../images/compricer-logga.png"
import { apiUsers } from "../../../utils/enums"

const VerifyPinCodePage = ({ location }) => {
  const theme = useTheme()
  const { state, dispatch } = useContext(QuoteContext)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [DisplayNewPin, setDisplayNewPin] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    let timeout = {}

    if (state.isLoading) {
      timeout = setTimeout(() => {
        setShowLoader(true)
      }, 50)
    } else {
      setShowLoader(false)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [state.isLoading])

  useEffect(() => {
    dispatch(formResetError())

    const timeout = setTimeout(() => {
      setDisplayNewPin(true)
    }, 35000)

    return () => {
      clearTimeout(timeout) // this guarantees to run right before the next effect
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!state.hasQuote && !state.hasLoadedQuote) {
    if (typeof window === "undefined") {
      return null
    }

    // If reloaded go back to start
    if (location.search !== "") {
      navigate(`/s?${new URLSearchParams(location.search).toString()}`)
    } else {
      navigate("/")
    }

    return null
  }

  const handleSubmit = e => {
    e.preventDefault()

    const isValid = validateOneTimePin(state.fields.oneTimePin.fieldValue)

    if (isValid.valid) {
      dispatch(verifyMobilePin(1))
    } else {
      dispatch(
        updateField({
          name: "oneTimePin",
          value: state.fields.oneTimePin.fieldValue,
          valid: false,
          message: isValid.message,
          dirty: true,
        })
      )
    }
  }

  const customerStatus = state.quote.result.customerStatus

  const requestNewPin = e => {
    dispatch(requestMobilePin())

    // Set timeout to prevent spam
    setDisplayNewPin(false)
    setTimeout(() => setDisplayNewPin(true), 35000)

    setButtonDisabled(true)
    setTimeout(() => setButtonDisabled(false), 35000)
  }

  return (
    <Layout>
      <SEO title="evoli - Verifiera ditt mobilnummer" noIndex={true} />
      <QuoteContainer>
        <QuoteHeroContent>
          <Icon
            name="thumbs-up"
            bgColor={theme.colors.CardContainer.iconBackground}
            iconColor={theme.colors.CardContainer.iconColor}
            iconSize="25"
          />

          <h1>Ditt hem är nu försäkrat!</h1>

          {state.hasLoadedQuote && (
            <p>
              <UpperCase>{state.quote.result.address}</UpperCase> har nu en
              giltig <LowerCase>{state.quote.result.insTypeExt}</LowerCase> från
              och med {state.quote.result.startDate}
            </p>
          )}

          {!state.hasLoadedQuote && (
            <p>
              <UpperCase>{state.quote.result.address}</UpperCase> har nu en
              giltig <LowerCase>{state.insTypeExt}</LowerCase> från och med{" "}
              {state.fields.startDate.fieldValue}
            </p>
          )}

          <h2>Betala för din försäkring</h2>

          <p style={{ color: "#727299", fontSize: "12px", maxWidth: "37ch" }}>
            Verifiera ditt mobilnummer för att betala inom 14 dagar så
            försäkringen inte blir ogiltig.
          </p>

          <ul>
            <li>
              <span>1</span>Verifiera ditt mobilnummer
            </li>
            <li>
              <span>2</span>Välj betalsätt
            </li>
          </ul>

          <FormContainer onSubmit={handleSubmit} noValidate>
            <FormLabel>Ange koden som skickats till dig:</FormLabel>

            <OneTimePin phone={` till ${state.quote.result.telephoneNumber}`} />

            <Button disabled={showLoader}>
              {!showLoader ? "Gå till betalning" : <ButtonLoader />}
            </Button>

            {state.error && <FormError>{state.error}</FormError>}

            {state.hasLoadedQuote &&
              state.loadedQuote.result.apiUser.id === apiUsers.INSPLANET.id && (
                <LogoContainer>
                  <img src={insplanet} alt="Insplanet logga" />
                </LogoContainer>
              )}
            {state.hasLoadedQuote &&
              state.loadedQuote.result.apiUser.id === apiUsers.COMPRICER.id && (
                <LogoContainer>
                  <img src={compricer} alt="Compricer logga" />
                </LogoContainer>
              )}

            <SecondaryActions>
              {DisplayNewPin && (
                <>
                  <OutlinedButton
                    text="Skicka en ny kod"
                    type="button"
                    onClick={() => setConfirm(true)}
                  />
                </>
              )}
            </SecondaryActions>
          </FormContainer>
        </QuoteHeroContent>
      </QuoteContainer>
      <Overlay show={confirm} />

      <Modal show={confirm}>
        <Icon
          name="code"
          bgColor={theme.colors.CardContainer.iconBackground}
          iconColor={theme.colors.CardContainer.iconColor}
          iconSize="25"
        />
        {state.dispatchedPin ? (
          <ModalLastStep
            phoneNumber={
              customerStatus === "active"
                ? state.quote.result.telephoneNumber
                : state.fields.telephoneNumber.fieldValue
            }
          />
        ) : (
          <ModalDefault
            phoneNumber={
              customerStatus === "active"
                ? state.quote.result.telephoneNumber
                : state.fields.telephoneNumber.fieldValue
            }
            requestNewPin={requestNewPin}
            buttonDisabled={buttonDisabled}
            modalError={state.errors.modalError}
            dispatch={dispatch}
            isLoading={state.isLoading}
          />
        )}

        <LinkButton
          text="Stäng"
          type="button"
          onClick={() => {
            setConfirm(false)
            dispatch(toggleDispatchedPin(false))
            dispatch(modalResetError())
          }}
        />
      </Modal>
    </Layout>
  )
}

export default VerifyPinCodePage

const UpperCase = styled.span`
  text-transform: uppercase;
`
const LowerCase = styled.span`
  text-transform: lowercase;
`

const LogoContainer = styled.div`
  max-width: 130px;
  margin-top: 10px;
`
const FormError = styled.div`
  color: #ff4984;
  margin: 0;
  margin-top: 5px;
  font-size: 12px;
  text-align: center;

  /* @media (max-width: 375px) {
    margin-top: -15px;
  } */
`
const FormLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #63638f;
  margin-bottom: 10px;
`

const QuoteHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 374px) {
    margin-top: 0px;
  }

  h1,
  p {
    max-width: 280px;
  }

  h1 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;
    /* max-width: 240px; */

    span {
      display: block;
      color: #5558ea;
      margin-top: 10px;
    }
  }

  h2 {
    margin: 0;
    margin-top: 25px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #5558ea;
  }

  p {
    margin-top: 6px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;

    max-width: 280px;
  }

  ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    margin-top: 12px;
    /* margin-bottom: 10px; */
    max-width: 280px;
  }

  li {
    color: #5558ea;
    font-size: 12px;
    font-weight: 500;
    display: flex;

    align-items: center;

    span {
      margin-right: 8px;
      background: ${props => (props.darkMode ? "#5558ea" : "#c4ffe1")};
      color: ${props => (props.darkMode ? "#c4ffe1" : "#5558ea")};
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

const SecondaryActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  justify-content: space-between;
  /* height: 39px; */
  button:last-child {
    margin-top: 15px;
  }
  margin-bottom: 40px;

  @media (max-width: 320px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`

const FormContainer = styled.form`
  margin: 0;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 374px) {
    margin-top: 0px;
  }
`

const ModalDefault = ({
  phoneNumber,
  requestNewPin,
  buttonDisabled,
  modalError,
  isLoading,
}) => {
  return (
    <>
      <h1>
        Skicka en ny engångskod till
        <span>{phoneNumber}</span>
      </h1>

      <Button
        type="button"
        style={{ width: "100%" }}
        onClick={requestNewPin}
        disabled={buttonDisabled}
      >
        {isLoading ? <ButtonLoader /> : "Ja, skicka en ny engångskod"}
      </Button>

      <FormMessage message={modalError} />
    </>
  )
}

const ModalLastStep = ({ phoneNumber }) => {
  return (
    <>
      <div>
        <h1>
          Vi har skickat en engångskod till
          <span>{phoneNumber}</span>
        </h1>
      </div>

      <p style={{ height: "48px", maxWidth: "300px" }}>
        Vänligen klicka på stäng nedan och ange sedan engångskoden vi skickat
        till din mobil.
      </p>
    </>
  )
}

const Button = styled.button`
  width: 212px;
  background: linear-gradient(
    180deg,
    rgba(107, 60, 231, 1) 5%,
    rgba(87, 55, 213, 1) 100%
  );
  color: #ffffff;
  border-radius: 0.3rem;
  font-size: 16px;
  height: 48px;
  border: 0;
  margin-top: 20px;
  padding: 10px 20px;

  @media (max-width: 375px) {
    margin-top: 0;
  }

  :disabled {
    opacity: 0.3;

    :hover {
      cursor: not-allowed;
    }
  }

  :hover {
    background: linear-gradient(
      180deg,
      rgba(107, 60, 231, 0.9) 5%,
      rgba(87, 55, 213, 0.9) 100%
    );
    cursor: pointer;
  }
`
